import request from '@/utils/request'


// 查询商品分类列表
export function listCategory(query) {
  return request({
    url: '/goods/category/list',
    method: 'get',
    params: query
  })
}
// 查询商品分类列表平台
export function listCategoryPlat(query) {
  return request({
    url: '/goods/category/listPlat',
    method: 'get',
    params: query
  })
}
// 查询商品分类分页
export function pageCategory(query) {
  return request({
    url: '/goods/category/page',
    method: 'get',
    params: query
  })
}

// 查询商品分类详细
export function getCategory(data) {
  return request({
    url: '/goods/category/detail',
    method: 'get',
    params: data
  })
}

// 新增商品分类
export function addCategory(data) {
  return request({
    url: '/goods/category/add',
    method: 'post',
    data: data
  })
}

// 修改商品分类
export function updateCategory(data) {
  return request({
    url: '/goods/category/edit',
    method: 'post',
    data: data
  })
}

export function updateVisible(data) {
  return request({
    url: '/goods/category/visible',
    method: 'post',
    data: data
  })
}

// 删除商品分类
export function delCategory(data) {
  return request({
    url: '/goods/category/delete',
    method: 'post',
    data: data
  })
}
